import { mapActions, mapGetters } from 'vuex';
import openBillingWindow from '../../../helpers/openBillingWindow';
import _laxios from '../../../store/_laxios';

const packageTypes = {
  standart: 2,
  premium: 3,
};

export default {
  name: 'ChoosePackage',
  data() {
    return {
      test: false,
      cityId: 0,
      showList: true,
      period: 1,
      companyDealerData: undefined,
      packageTypes,
    };
  },
  i18n: require('../i18n').default,
  mixins: [require('../../../mixins/slonEventLogger')],
  methods: {
    ...mapActions({
      fetchCenters: 'shared/locations/fetchCenters',
      autoDealerBilling_: 'others/autoDealerBilling',
    }),
    setCityId(id) {
      this.cityId = id;
      this.showList = true;
    },
    autoDealerBilling({ packageType = 2 }) {
      const params = {
        device: this.isDesktop ? 'desktop' : 'mobile',
        packageType,
        pageName: 'companyAdd',
        period: this.period,
        typeId: 2,
      };
      this.autoDealerBilling_(params).then(({ orderId = 0 }) => {
        if (orderId) {
          openBillingWindow(`${orderId}?personal_account_project_id=1`, '_blank', '');
          this.sendEventToSlonik(829, { click_action: packageType === 2 ? 46 : 45, billing_order_id: orderId });
        } else {
          console.error('Wrong orderId');
        }
      }).catch((e) => console.error(e));
    },
    getUserPackagesByCity() {
      return this.companyDealerData?.[Number(this.cityId) === 10 ? 'kiev' : 'other'] || {};
    },
    getUserPackagesByPeriod() {
      return this.getUserPackagesByCity()?.[Number(this.period) === 1 ? 'one' : 'three'] || {};
    },
    getUserPackagesByType(packageType) {
      return this.getUserPackagesByPeriod()?.[Number(packageType) === this.packageTypes.standart ? 'standard' : 'premium'];
    },
    isDisabledPackage(packageType) {
      return this.getUserPackagesByType(packageType)?.exceptions?.length;
    },
  },
  computed: {
    ...mapGetters({
      userData_: 'Common/userData',
      cities_: 'shared/locations/l10n',
      personalManager_: 'others/personalManager',
    }),
    personalManager() {
      return Object.keys(this._result(this.personalManager_, 'managerInfo', {})).length ? this._result(this.personalManager_, 'managerInfo') : {};
    },
    myPersonalManager() {
      return Object.keys(this.personalManager).length && this.userId ? this.personalManager : this.defaultManagerList[this.defaultManager];
    },
    clearManagerPhone() {
      const phone = this._result(this.myPersonalManager, 'phone', '').replace(/\D+/g, '') || ''
      return phone || '(067) 430-49-27';
    },
    cities() {
      return this.cities_ || {};
    },
    profileRegionId() {
      const id = this._result(this.userData_, 'location.state.id', 0);
      this.cityId = id || 10;
      return id;
    },
    userId() {
      return this._result(this.userData_, 'userId', 0);
    },
    myCity() {
      return this.cities[this.cityId || this.profileRegionId || 10] || {};
    },
    prices() {
      return this.cityId === 10 ? { standard: 4200, premium: 8820 } : { standard: 3280, premium: 8320 };
    },
    packagePrices() {
      const getPercentage = (sum, percentage = 10) => (sum / 100) * percentage;
      const packages = {};
      Object.keys(this.prices).map((key) => {
        Object.assign(packages, {
          [key]: {
            one: this.prices[key],
            three: this.prices[key] * 3 - getPercentage(this.prices[key] * 3, 10),
            discount: getPercentage(this.prices[key] * 3, 10),
          },
        });
      });
      return packages;
    },
    autoChecks() {
      return { standard: 3, premium: 5 };
    },
  },
  async mounted() {
    this.fetchCenters();
    try {
      const response = await _laxios.getCompanyDealerData.request();
      if (response?.priceData) {
        this.companyDealerData = response.priceData;
      }
    } catch (e) {
      console.error('Помилка отримання данних getCompanyDealerData');
    }
  },
};
