export default {
	messages: {
		ru: {
			'вашого': 'вашего',
			'автомобільного бізнесу': 'автомобильного бизнеса',
			'Бонусні публікації та збільшені вигоди на просування': 'Бонусные публикации и увеличенные выгоды на продвижение',
			'Інструменти для бізнесу: розширена статистика пропозицій, журнал дзвінків та їх запис': 'Инструменты для бизнеса: расширенная статистика предложений, журнал звонков и их запись',
			'Впізнаваність вашої компанії: логотип, назва і сайт, панорамні фото, перелік послуг на сторінці пропозицій': 'Узнаваемость вашей компании: логотип, название и сайт, панорамные фото, перечень услуг на странице предложений',
			'Всі переваги': 'Все преимущества',
			'розміщення': 'размещения',
			'Почати продавати': 'Начать продавать',
			'Україні з продажу': 'Украине по продаже',
			'відвідувачів щодня': 'посетителей ежедневно',
			'тис': 'тыс',
			'покупців щодня': 'покупателей ежедневно',
			'Брендування сторінки вашої компанії': 'Брендирование страницы вашей компании',
			'Панорамні': 'Панорамные',
			'та назва': 'и название',
			'Ваші контакти': 'Ваши контакты',
			'Посилання': 'Ссылка',
			'Ваша компанія в каталозі автодилерів': 'Ваша компания в каталоге автодилеров',
			'Ідентифікація на сторінках всіх ваших': 'Идентификация на страницах всех ваших',
			'Всі ваші': 'Все ваши',
			'Більше довіри до ваших пропозицій у пошуку': 'Больше доверия к вашим предложениям в поиске',
			'Можливості для аналітики та просування': 'Возможности для аналитики и продвижения',
			'Технологія «Коллтрекінг» для роботи з дзвінками': 'Технология «Коллтрекинг» для работы со звонками',
			'База активних покупців': 'База активных покупателей',
			'Детальний звіт ефективності': 'Детальный отчет эффективности',
			'Записи розмов із покупцями': 'Записи разговоров с покупателями',
			'фіксації дзвінків': 'фиксации звонков',
			'Розширена статистика розміщення за всіма показниками': 'Расширенная статистика размещения по всем показателям',
			'Сума публікацій': 'Сумма публикаций',
			'Ваші': 'Ваши',
			'Перегляди в пошуку': 'Просмотры в поиске',
			'чати': 'чаты',
            'Ви отримуєте додаткові перегляди та дзвінки': 'Вы получаете дополнительные просмотры и звонки',
            'Промо-розсилки ваших авто зацікавленій аудиторії': 'Промо-рассылки ваших авто заинтересованной аудитории',
            'Спеціальні алгоритми для залучення покупців': 'Специальные алгоритмы для привлечения покупателей',
            'Ми підвищуємо рівень довіри до вашої компанії': 'Мы повышаем уровень доверия к вашей компании',
            'Високі місця вашої компанії у пошукових системах': 'Высокие места вашей компании в поисковых системах',
            'Безкоштовна реклама за пошуковими запитами': 'Бесплатная реклама по поисковым запросам',
            'Додатковий трафік на сторінку компанії': 'Дополнительный трафик на страницу компании',
            'Більше переглядів ваших авто покупцями': 'Больше просмотров ваших авто покупателями',
			'Опубліковані авто': 'Опубликованные авто',
			'Відкриття номерів телефону': 'Открытие номеров телефона',
			'Виберіть пакет для вашої області': 'Выберите пакет для вашей области',
			'місяць': 'месяц',
			'місяці': 'месяца',
			'Автодилер Преміум': 'Автодилер Премиум',
			'безкоштовних публікацій': 'бесплатных публикаций',
			'безкоштовні публікації': 'бесплатные публикации',
			'Для компаній, що працюють у високому ціновому сегменті чи з великою к-стю авто': 'Для компаний, работающих в высоком ценовом сегменте или с большим кол-вом авто',
			'рахунок просування': 'счет продвижения',
			// 'за допомогою рівнів': 'с помощью уровней',
			'вигоди': 'выгоды',
			'на необмежену кількість публікацій': 'на неограниченное количество публикаций',
			'на підняття в ТОП': 'на поднятие в ТОП',
			'розширені перевірки': 'расширенные проверки',
			'розширені перевірки2': 'расширенных проверок',
			'частка': 'доля',
			'Схожі оголошення': 'Похожие объявления',
			'Відмітка': 'Отметка',
			'Акція': 'Акция',
			'на ваших оголошеннях в пошуку': 'на ваших объявлениях в поиске',
			'Максимальна економія': 'Максимальная экономия',
			'міс': 'мес',
			'Замовити пакет': 'Заказать пакет',
			'Преміум': 'Премиум',
			'Для компаній, що працюють у низькому ціновому сегменті чи з малою к-стю авто': 'Для компаний, работающих в низком ценовом сегменте или с малым кол-вом авто',
			'розширених перевірки': 'расширенных проверки',
			'блоці': 'блоке',
			'економії': 'экономии',
			'В нас уже продають': 'У нас уже продают',
			'Переглянути всі компанії': 'Посмотреть все компании',
			'Часті запитання': 'Часто задаваемые вопросы',
			'Які документи потрібні для реєстрації': 'Какие документы нужны для регистрации',
			'Для ТОВ': 'Для ООО: выписка из Единого государственного реестра юридических лиц, подписанная с помощью ЭЦП через ЭДО;',
			'Для ФОП': 'Для ФЛП: выписка из Единого государственного реестра физических лиц, копия паспорта, идентификационный код, авторизация в кабинете пользователя через ДІЮ',
			'Скільки коштують панорамні фото та подача анкети': 'Сколько стоят панорамные фото и подача анкеты',
			'Ці послуги безкоштовні. Наш інспектор приїжджає до вашого салону/майданчика, щоб перевірити відповідність адреси й фактичну наявність автомобілів. Він же й робить панорамні фото, щоб виділити вас для покупця серед усіх Автодилерів України': 'Это совершенно бесплатно. Наш инспектор выезжает к вам в салон/на площадку, чтобы проверить соответствие адреса и наличия авто. Он же делает панорамные фото, чтобы выделить вас для покупателя среди всех Автодилеров Украины',
			'Що дають перевірки VIN+ і навіщо вони мені': 'Что дают проверки VIN + и зачем они мне',
			'Додаючи звіт розширеної перевірки, ви підтверджуєте тим самим характеристики авто, вказані у пропозиції та демонструєте, що з минулим авто все гаразд. Цим ви можете зацікавити більше покупців та пришвидшити продаж авто, які продаються вже тривалий час. Ну, й крім того, допоможете розвивати прозорий авторинок': 'Добавляя расширенный отчет по проверке авто, вы подтверждаете технические характеристики указанные в объявлении и демонстрируете, что с историей эксплуатации авто все прозрачно. Этим вы можете заинтересовать больше покупателей и ускорить продажу авто, которые продаются уже какое-то время. Также вы делаете вклад в развитие проверенного авторынка Украины',
			'Що таке коллтрекінг': 'Что такое коллтрекинг',
			'Покупець бачить на сторінці авто підмінний номер телефону й телефонує саме за ним. Це допоможе вам визначити, що покупець прийшов саме з AUTO.RIA. Вся інформація про дзвінок включно з записом розмови зберігається у Журналі дзвінків': 'При открытии номера, покупатель видит подменный номер телефона и звонит именно по нему. Это вам поможет точно понимать количество звонков с AUTO.RIA. Вся информация о звонке вместе с записью сохраняется в вашем личном журнале звонков',
			'Записані телефонні розмови лишаються конфіденційними': 'Записанные телефонные разговоры остаются конфиденциальными',
			'Звісно! Вся інформація наших клієнтів, у тому числі й записи розмов — конфіденційна інформація, яка не передається третім особам. Усі записи зберігаються на надійних серверах': 'Обязательно! Информация наших клиентов, в том числе записи разговоров, являются конфиденциальными и не передаются третьим лицам. Все записи звонков хранятся на надежных серверах',
			'всього': 'всего',
			'використано': 'использовано',
			'від': 'от',
			'Додавайте сторінку автодилерів для свого бізнесу і продавайте авто по всій Україні': 'Добавляйте страницу Автодилера для своего бизнеса и продавайте авто по всей Украине',
			'Реєструйте свій бізнес з продажу авто на AUTO.RIA і отримаєте переваги офіційного дилера з продажу б/в авто в Україні. Додавання компанії на AUTO.RIA забезпечить вам ряд переваг для швидкого продажу авто по всій країні, дізнайтесь які на сторінці додавання!': 'Регистрируйте свой бизнес по продаже авто на AUTO.RIA и получите преимущества официального дилера по продаже б/у авто в Украине. Добавление компании на AUTO.RIA обеспечит вам ряд преимуществ для быстрой продажи авто по всей стране, узнайте какие на странице добавления!',
			'технічну': 'техническую',
			'перевірку': 'проверку',
			'Термін дії придбаних пакетів перевищує максимальний ліміт. Зверніться до свого менеджера:' : 'Срок действия купленых пакетов превышает максимальный лимит. Обратитесь к своему менеджеру:'
		},
		uk: {
			'вашого': 'вашого',
			'автомобільного бізнесу': 'автомобільного бізнесу',
			'Бонусні публікації та збільшені вигоди на просування': 'Бонусні публікації та збільшені вигоди на просування',
			'Інструменти для бізнесу: розширена статистика пропозицій, журнал дзвінків та їх запис': 'Інструменти для бізнесу: розширена статистика пропозицій, журнал дзвінків та їх запис',
			'Впізнаваність вашої компанії: логотип, назва і сайт, панорамні фото, перелік послуг на сторінці пропозицій': 'Впізнаваність вашої компанії: логотип, назва і сайт, панорамні фото, перелік послуг на сторінці пропозицій',
			'Всі переваги': 'Всі переваги',
			'розміщення': 'розміщення',
			'Почати продавати': 'Почати продавати',
			'Україні з продажу': 'Україні з продажу',
			'відвідувачів щодня': 'відвідувачів щодня',
			'тис': 'тис',
			'покупців щодня': 'покупців щодня',
			'Брендування сторінки вашої компанії': 'Брендування сторінки вашої компанії',
			'Панорамні': 'Панорамні',
			'та назва': 'та назва',
			'Ваші контакти': 'Ваші контакти',
			'Посилання': 'Посилання',
			'Ваша компанія в каталозі автодилерів': 'Ваша компанія в каталозі автодилерів',
			'Ідентифікація на сторінках всіх ваших': 'Ідентифікація на сторінках всіх ваших',
			'Всі ваші': 'Всі ваші',
			'Більше довіри до ваших пропозицій у пошуку': 'Більше довіри до ваших пропозицій у пошуку',
			'Можливості для аналітики та просування': 'Можливості для аналітики та просування',
			'Технологія «Коллтрекінг» для роботи з дзвінками': 'Технологія «Коллтрекінг» для роботи з дзвінками',
			'База активних покупців': 'База активних покупців',
			'Детальний звіт ефективності': 'Детальний звіт ефективності',
			'Записи розмов із покупцями': 'Записи розмов із покупцями',
			'фіксації дзвінків': 'фіксації дзвінків',
			'Розширена статистика розміщення за всіма показниками': 'Розширена статистика розміщення за всіма показниками',
			'Сума публікацій': 'Сума публікацій',
			'Ваші': 'Ваші',
			'Перегляди в пошуку': 'Перегляди в пошуку',
			'чати': 'чати',
            'Ви отримуєте додаткові перегляди та дзвінки': 'Ви отримуєте додаткові перегляди та дзвінки',
            'Промо-розсилки ваших авто зацікавленій аудиторії': 'Промо-розсилки ваших авто зацікавленій аудиторії',
            'Спеціальні алгоритми': 'Спеціальні алгоритми',
            'для залучення покупців': 'для залучення покупців',
            'Ми підвищуємо рівень довіри до вашої компанії': 'Ми підвищуємо рівень довіри до вашої компанії',
            'Високі місця вашої компанії у пошукових системах': 'Високі місця вашої компанії у пошукових системах',
            'Безкоштовна реклама за пошуковими запитами': 'Безкоштовна реклама за пошуковими запитами',
            'Додатковий трафік на сторінку компанії': 'Додатковий трафік на сторінку компанії',
            'Більше переглядів ваших авто покупцями': 'Більше переглядів ваших авто покупцями',
			'Опубліковані авто': 'Опубліковані авто',
			'Відкриття номерів телефону': 'Відкриття номерів телефону',
			'Виберіть пакет для вашої області': 'Виберіть пакет для вашої області',
			'місяць': 'місяць',
			'місяці': 'місяці',
			'Автодилер Преміум': 'Автодилер Преміум',
			'безкоштовних публікацій': 'безкоштовних публікацій',
			'безкоштовні публікації': 'безкоштовні публікації',
			'Для компаній, що працюють у високому ціновому сегменті чи з великою к-стю авто': 'Для компаній, що працюють у високому ціновому сегменті чи з великою к-стю авто',
			'рахунок просування': 'рахунок просування',
			'за допомогою рівнів': 'за допомогою рівнів',
			'вигоди': 'вигоди',
			'на необмежену кількість публікацій': 'на необмежену кількість публікацій',
			'на підняття в ТОП': 'на підняття в ТОП',
			'розширені перевірки': 'розширені перевірки',
			'розширені перевірки2': 'розширених перевірок',
			'частка': 'частка',
			'Схожі оголошення': 'Схожі оголошення',
			'Відмітка': 'Відмітка',
			'Акція': 'Акція',
			'на ваших оголошеннях в пошуку': 'на ваших оголошеннях в пошуку',
			'Максимальна економія': 'Максимальна економія',
			'міс': 'міс',
			'Замовити пакет': 'Замовити пакет',
			'Преміум': 'Преміум',
			'Для компаній, що працюють у низькому ціновому сегменті чи з малою к-стю авто': 'Для компаній, що працюють у низькому ціновому сегменті чи з малою к-стю авто',
			'розширених перевірки': 'розширених перевірки',
			'блоці': 'блоці',
			'економії': 'економії',
			'В нас уже продають': 'В нас уже продають',
			'Переглянути всі компанії': 'Переглянути всі компанії',
			'Часті запитання': 'Часті запитання',
			'Які документи потрібні для реєстрації': 'Які документи потрібні для реєстрації',
			'Для ТОВ': 'Для ТОВ: витяг із Єдиного державного реєстру юридичних осіб, підписаний за допомогою ЕЦП через ЕДО;',
			'Для ФОП': 'Для ФОП: витяг із Єдиного державного реєстру фізичних осіб, копія паспорта, ідентифікаційний код, авторизація в кабінеті користувача через ДІЮ',
			'Скільки коштують панорамні фото та подача анкети': 'Скільки коштують панорамні фото та подача анкети',
			'Ці послуги безкоштовні. Наш інспектор приїжджає до вашого салону/майданчика, щоб перевірити відповідність адреси й фактичну наявність автомобілів. Він же й робить панорамні фото, щоб виділити вас для покупця серед усіх Автодилерів України': 'Ці послуги безкоштовні. Наш інспектор приїжджає до вашого салону/майданчика, щоб перевірити відповідність адреси й фактичну наявність автомобілів. Він же й робить панорамні фото, щоб виділити вас для покупця серед усіх Автодилерів України',
			'Що дають перевірки VIN+ і навіщо вони мені': 'Що дають перевірки VIN+ і навіщо вони мені',
			'Додаючи звіт розширеної перевірки, ви підтверджуєте тим самим характеристики авто, вказані у пропозиції та демонструєте, що з минулим авто все гаразд. Цим ви можете зацікавити більше покупців та пришвидшити продаж авто, які продаються вже тривалий час. Ну, й крім того, допоможете розвивати прозорий авторинок': 'Додаючи звіт розширеної перевірки, ви підтверджуєте тим самим характеристики авто, вказані у пропозиції та демонструєте, що з минулим авто все гаразд. Цим ви можете зацікавити більше покупців та пришвидшити продаж авто, які продаються вже тривалий час. Ну, й крім того, допоможете розвивати прозорий авторинок',
			'Що таке коллтрекінг': 'Що таке коллтрекінг',
			'Покупець бачить на сторінці авто підмінний номер телефону й телефонує саме за ним. Це допоможе вам визначити, що покупець прийшов саме з AUTO.RIA. Вся інформація про дзвінок включно з записом розмови зберігається у Журналі дзвінків': 'Покупець бачить на сторінці авто підмінний номер телефону й телефонує саме за ним. Це допоможе вам визначити, що покупець прийшов саме з AUTO.RIA. Вся інформація про дзвінок включно з записом розмови зберігається у Журналі дзвінків',
			'Записані телефонні розмови лишаються конфіденційними': 'Записані телефонні розмови лишаються конфіденційними',
			'Звісно! Вся інформація наших клієнтів, у тому числі й записи розмов — конфіденційна інформація, яка не передається третім особам. Усі записи зберігаються на надійних серверах': 'Звісно! Вся інформація наших клієнтів, у тому числі й записи розмов — конфіденційна інформація, яка не передається третім особам. Усі записи зберігаються на надійних серверах',
			'всього': 'всього',
			'використано': 'використано',
			'від': 'від',
			'Додавайте сторінку автодилерів для свого бізнесу і продавайте авто по всій Україні': 'Додавайте сторінку автодилерів для свого бізнесу і продавайте авто по всій Україні',
			'Реєструйте свій бізнес з продажу авто на AUTO.RIA і отримаєте переваги офіційного дилера з продажу б/в авто в Україні. Додавання компанії на AUTO.RIA забезпечить вам ряд переваг для швидкого продажу авто по всій країні, дізнайтесь які на сторінці додавання!': 'Реєструйте свій бізнес з продажу авто на AUTO.RIA і отримаєте переваги офіційного дилера з продажу б/в авто в Україні. Додавання компанії на AUTO.RIA забезпечить вам ряд переваг для швидкого продажу авто по всій країні, дізнайтесь які на сторінці додавання!',
			'технічну': 'технічну',
			'перевірку': 'перевірку',
			'Термін дії придбаних пакетів перевищує максимальний ліміт. Зверніться до свого менеджера:' : 'Термін дії придбаних пакетів перевищує максимальний ліміт. Зверніться до свого менеджера:'
		}
	}
};
