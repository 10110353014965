import { mapActions, mapGetters } from 'vuex';
import isOnline from '../../../mixins/isOnline';

export default {
	name: 'PersonalManager',
	props: {
		defaultManager: {
			// Ставим дефолтного менеджера для не авторизованих юзерів із списку defaultManagerList
			type: Number,
			default: 1,
		},
		setManager: {
			// Якщо треба засетапити для всіх менеджера із defaultManagerList
			type: Number,
			default: 0,
		},
	},
	mixins: [isOnline],
	data() {
		return {
			defaultManagerList: {
				1: {
					name: this.$t('Наталії'),
					phone: '(067) 430-49-27',
					photo: 'photos/pictures/common/1/147/14763/14763l.jpg',
				},
			},
			time: '',
			phone: '',
			showError: false,
			successful: false,
		};
	},
	methods: {
		...mapActions({
			fetchPersonalManager_: 'others/getPersonalManager',
			reCallToMe_: 'others/reCallToMe',
		}),
		fetchPersonalManager() {
			if (!this.setManager) {
				return this.fetchPersonalManager_();
			}
		},
		send() {
			const formData = new FormData();
			const formattedPhone = this.phone.replace(/^\+?3?8?/, '');
			formData.append('user_id', this.userId);
			formData.append('direction', 32);
			formData.append('messageId', 11);
			formData.append('name', this.userName);
			formData.append('phones[]', formattedPhone);
			formData.append('message', `В поле "время удобное для звонка" пользователь указал: ${this.time}`);
			this.reCallToMe_(formData).then(({err = ''}) => {
				if (err) {
					this.showError = true;
				} else {
					this.successful = true;
				}
			}).catch(e => {
				console.error(e);
				this.showError = true;
			});
		},
	},
	computed: {
		...mapGetters({
			personalManager_: 'others/personalManager',
			userData_: 'Common/userData',
		}),
		personalManager() {
			return Object.keys(this._result(this.personalManager_, 'managerInfo', {})).length ? this._result(this.personalManager_, 'managerInfo') : {};
		},
		userId() {
			return this._result(this.userData_, 'userId', 0);
		},
		userName() {
			return this._result(this.userData_, 'userName', '');
		},
		myPersonalManager() {
			return Object.keys(this.personalManager).length && this.userId ? this.personalManager : this.defaultManagerList[this.defaultManager];
		},
		clearManagerPhone() {
			return this._result(this.myPersonalManager, 'phone', '').replace(/\D+/g, '') || '';
		},
		userPhone() {
			const userPhones = this._result(this.userData_, 'userPhones', []);
			const phone = userPhones[0] || '';
			return phone.replace(/[()\s]/g, '');
		},
	},
	mounted() {
		this.fetchPersonalManager();
	},
	watch: {
		userPhone(newValue) {
			if (newValue) {
				this.phone = newValue;
			}
		}
	},
	i18n: require('./i18n').default,
};
