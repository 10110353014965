export default {
	messages: {
		ru: {
			'Залишились запитання': 'Остались вопросы',
			'Звертайтесь до менеджера проекту': 'Обращайтесь к менеджеру проекта',
			'Замовити зворотній дзвінок': 'Заказать обратный звонок',
			'Зручний час': 'Удобное время',
			'Ваші персональні дані будуть оброблені': 'Ваши персональные данные будут обработаны и защищены в соответствии с',
			'Політики приватності': 'Политикой конфиденциальности',
			'Передзвоніть мені': 'Перезвоните мне',
			'Наталії': 'Натальи',
			'Дякуємо, менеджер зв’яжеться з вами найближчим часом.': 'Спасибо, менеджер свяжется с вами в ближайшее время.',
			'Ой, щось пішло не так :( Будь ласка, перевірте правильність заповнення форми.': 'Ой, что-то пошло не так :( Пожалуйста, проверьте правильность заполнения формы.',
		},
		uk: {
			'Залишились запитання': 'Залишились запитання',
			'Звертайтесь до менеджера проекту': 'Звертайтесь до менеджера проекту',
			'Замовити зворотній дзвінок': 'Замовити зворотній дзвінок',
			'Зручний час': 'Зручний час',
			'Ваші персональні дані будуть оброблені': 'Ваші персональні дані будуть оброблені та захищені відповідно до',
			'Політики приватності': 'Політики приватності',
			'Передзвоніть мені': 'Передзвоніть мені',
			'Наталії': 'Наталії',
			'Дякуємо, менеджер зв’яжеться з вами найближчим часом.': 'Дякуємо, менеджер зв’яжеться з вами найближчим часом.',
			'Ой, щось пішло не так :( Будь ласка, перевірте правильність заповнення форми.': 'Ой, щось пішло не так :( Будь ласка, перевірте правильність заповнення форми.',
		}
	}
};
