export default {
	data() {
		return {
			mBefore: '09:00',
			mAfter: '18:00',
			mDays: [1, 2, 3, 4, 5],
		}
	},
	computed: {
		isOnline() {
			const format = 'hh:mm';
			const time = this.m(new Date(), format);
			const beforeTime = this.m(this.mBefore, format);
			const afterTime = this.m(this.mAfter, format);
			return time.isBetween(beforeTime, afterTime) && this.mDays.includes(time.day());
		},
		currentDay() {
			return this.m(new Date()).day();
		},
	},
	methods: {
		isOnlineSettings(from, to, days = []) {
			// @todo check params
			this.mBefore = from;
			this.mAfter = to;
			this.mDays = days;
		}
	}
};
