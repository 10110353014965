export default {
  name: 'Slider',
  data() {
    return {
      flickityOptions: {
        cellAlign: 'left',
        lazyLoad: true,
        contain: true,
      },
      index: 0,
    };
  },
  mixins: [require('../../../mixins/slonEventLogger')],
  components: {
    flickity: require('../../Common/Flickity/Flickity.vue').default,
  },
  computed: {
    srcImgSlideAdvancedFeatures() {
      return `https://css.riastatic.com/images/demo/avtodiler/cdadvancedfeatures${this.langCode === 'ru' ? 'Ru' : 'Ukr'}.png`;
    },
    srcsetImgSlideAdvancedFeatures() {
      return `https://css.riastatic.com/images/demo/avtodiler/cdadvancedfeatures${this.langCode === 'ru' ? 'Ru' : 'Ukr'}.webp`;
    },
    srcImgSlideTopPlaces() {
      return `https://css.riastatic.com/images/demo/avtodiler/topcompanyse${this.langCode === 'ru' ? 'Ru' : 'Ukr'}.png`;
    },
    srcsetImgSliderTopPlaces() {
      return `https://css.riastatic.com/images/demo/avtodiler/topcompanyse${this.langCode === 'ru' ? 'Ru' : 'Ukr'}.webp`;
    },
  },
  methods: {
    flickityEvents(event, listener) {
      this.$refs.flickity.on(event, listener);
    },
    changeSlides() {
      this.flickityEvents('change', (index) => {
        this.sendEventToSlonik(829, { click_action: this.index < index ? 31 : 32 });
        this.index = index;
      });
    },
  },
  i18n: require('../i18n').default,
};
