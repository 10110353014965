export default {
  name: 'LandingPageDealer',
  components: {
    'choose-package': require('./ChoosePackage/index.vue').default,
    answers: require('./Answers/index.vue').default,
    'logo-list': require('./LogoList/index.vue').default,
    'toggle-screen-shots': require('./ToggleScreenShots/index.vue').default,
    slider: require('./Slider/index.vue').default,
    'page-header': require('./Header/index.vue').default,
    info: require('./Info/index.vue').default,
    'personal-manager': require('../Common/PersonalManager/index.vue').default,
  },
  mixins: [require('../../mixins/slonEventLogger')],
  metaInfo() {
    return {
      title: `AUTO.RIA – ${this.$t('Додавайте сторінку автодилерів для свого бізнесу і продавайте авто по всій Україні')}`,
      meta: [
        { name: 'description', content: this.$t('Реєструйте свій бізнес з продажу авто на AUTO.RIA і отримаєте переваги офіційного дилера з продажу б/в авто в Україні. Додавання компанії на AUTO.RIA забезпечить вам ряд переваг для швидкого продажу авто по всій країні, дізнайтесь які на сторінці додавання!') },
        { property: 'og:site_name', content: 'AUTO.RIA' },
        { property: 'og:title', content: `AUTO.RIA – ${this.$t('Додавайте сторінку автодилерів для свого бізнесу і продавайте авто по всій Україні')}` },
        { property: 'og:description', content: this.$t('Реєструйте свій бізнес з продажу авто на AUTO.RIA і отримаєте переваги офіційного дилера з продажу б/в авто в Україні. Додавання компанії на AUTO.RIA забезпечить вам ряд переваг для швидкого продажу авто по всій країні, дізнайтесь які на сторінці додавання!') },
        { property: 'og:image', content: 'https://css.riastatic.com/images/og-placeholder.png' },
      ],
    };
  },
  mounted() {
    this.sendEventToSlonik(828);
  },
  methods: {
    logEvent(action) {
      this.sendEventToSlonik(829, { click_action: action + 60 });
    },
  },
  i18n: require('./i18n').default,
};
